<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="green" v-bind="attrs" v-on="on">
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </template>
          <span>Replace end url Domain</span>
        </v-tooltip>
      </div>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Replace end url Domain </span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                hide-details
                label="Old end url *"
                required
                v-model="old_domain"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                hide-details
                label="New end url *"
                required
                v-model="new_domain"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal"> Close </v-btn>
        <v-btn
          color="blue darken-1"
          text
          type="button"
          @click="onReplaceDomains"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requestStatus } from "@/services/services";
import { endUrlsReplace } from "@/store/modules/offers/api";
export default {
  props: ["updadeList"],
  data() {
    return {
      dialog: false,
      old_domain: "",
      new_domain: "",
    };
  },
  methods: {
    onReplaceDomains() {
      if (this.old_domain && this.new_domain) {
        endUrlsReplace({
          old_domain: this.old_domain,
          new_domain: this.new_domain,
        }).then((response) => {
          if (response.status === requestStatus.success) {
            this.$toaster.success("Domains end urls have been changed");
            this.updadeList();
            this.closeModal();
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        });
      } else {
        this.$toaster.error("Indicates required field.");
      }
    },

    closeModal() {
      this.old_domain = "";
      this.new_domain = "";
      this.dialog = false;
    },
  },
};
</script>
